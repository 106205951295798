import { Helmet } from 'react-helmet-async';

import { useSlugRedirect } from '@hooks/useSlugRedirect';

import { routes } from '@routes';

import { Header } from '@components/Header';
import { Content } from './components/Content';
import { PageProvider } from './providers/PageProvider';

export const AdminCanvasCoursesPage = () => {
  useSlugRedirect(routes.adminCanvasCoursesList);

  return (
    <>
      <Helmet>
        <title>Canvas Courses - AutoScript Admin</title>
      </Helmet>
      <Header title={'Canvas Courses'} />

      <PageProvider>
        <Content />
      </PageProvider>
    </>
  );
};
